<template>
    <div class="form-checkboxes">
      <label class="form-input-label">
        <Field
          :name="fieldName"
          :rules="{ required: { allowFalse: false } }"
          type="checkbox"
          class="form-checkbox-field d-block"
          :value="true"
        />
        <span class="form-checkbox-value" :class="{'is-error':errors }">
          <slot><span v-text="fieldLabel" /></slot>
        </span>
      </label>
    </div>
  </template>
<script>

  import formFields from '@/mixins/formFields'
  import { Field } from 'vee-validate'
  export default {
    name: 'InputCheckboxComponent',

    mixins: [ formFields ],

    components: { Field },

    props: {
      fieldName: {
        type: String,
        required: true
      },
      fieldLabel: {
        type: String,
        required: true
      },
      errors: {
        type: Object,
        default: () => {}
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  @import '../../styles/media-queries';
  @import '../../styles/colors';
  @import '../../styles/animations';
  .form-checkboxes {
      display: flex;
      position: relative;
      flex-direction: column;
      margin-top: 20px;

      @include media('>=sm') {
        flex-direction: row;
      }
  }

    .form-checkbox-field {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked:hover ~ .form-checkbox-value::before,
      &:checked ~ .form-checkbox-value::before {
        background-color: $color-grey-300;
      }

      &:checked ~ .form-checkbox-value::after {
        opacity: 1;
      }
    }

    .form-checkbox-value {
      display: block;
      padding-left: 30px;
      color: $color-grey-500;
      cursor: pointer;

      &.is-error {
        color: $color-red-500;
      }

      span {
        display: inline-block;
      }

      &::before {
        @include animation-in(background-color);

        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        border: 2px solid $color-grey-300;
        border-radius: 2px;
        background-color: $white;
      }

      &::after {
        @include animation-in(opacity);

        content: '';
        position: absolute;
        left: 8px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
    }
  </style>
