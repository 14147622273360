<template>
    <i18n-t keypath="form.termsConditionsLabel" tag="span">
      <template #termsOfUseLink>
        <a :href="termsOfUseUrl" target="_blank" @click.stop>{{ $tm('termsOfUse.links.termsOfUseLinkLabel') }}</a>
      </template>
      <template #dataPrivacyLink>
        <a :href="dataPrivacyUrl" target="_blank" @click.stop>{{ $tm('termsOfUse.links.dataPrivacyLinkLabel') }}</a>
      </template>
    </i18n-t>
</template>

<script>
import { appSettings } from '@/settings'

export default {
  name: 'TermsOfUseAndPrivacyPolicyLinks',

  computed: {
     dataPrivacyUrl () {
      return appSettings.links?.dataPrivacy
    },

    termsOfUseUrl () {
      return appSettings.links.termsOfUse
    }
  }
}
</script>
<style lang="scss" scoped>
  a {
    text-decoration: underline;
  }
</style>
